<template>
  <div>
    <Carousel
      :settings="settings"
      :wrap-around="true"
    >
      <Slide
        v-for="entry in entries"
        :key="entry"
      >
        <div class="ml-10 mr-10">
          <div>
            <img :src="entry.img">
          </div>
          <div class="px-8">
            <p class="font-medium text-green-1000 font-semibold pt-2 pb-4">
              {{ entry.title }}
            </p>
          </div>
        </div>
      </Slide>
      <template #addons>
        <navigation />
        <pagination />
      </template>
    </Carousel>
  </div>
</template>

<script>
  import 'vue3-carousel/dist/carousel.css';
  import { defineComponent } from 'vue';
  import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel';

  export default defineComponent({
    name: 'Vue3CarouselModal',
    components: {
      Carousel,
      Slide,
      Navigation,
      Pagination,
    },
    props: {
      entries: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
    }),
    mounted() {
      // console.log('mounted VueCarouselModal');
    },
  });
</script>

<style>
/* stylelint-disable selector-class-pattern */
:root {
  --vc-clr-primary: #748240;
  --vc-clr-secondary: #e5dfb9;
  --vc-pgn-height: 10px;
  --vc-pgn-width: 10px;
}
</style>
