<template>
  <div>
    <vue-final-modal
      v-model="showModal"
      :esc-to-close="escToClose"
      classes="modal-receptacle"
      content-class="modal-content"
      :style="cssVars"
      @opened="onOpen"
    >
      <button
        class="modal__close z-10"
        @click="showModal = false"
      >
        <i class="fa fa-close fa-circle fa-stack-2x text-white" />
      </button>
      <div
        class="modal__content overflow-y-auto"
        @click="showModal = true"
      >
        <slot />
      </div>
      <button
        class="bg-green-900 hover:bg-green-800 text-white p-2 text-sm"
        @click="showModal = false"
      >
        Close
      </button>
    </vue-final-modal>
    <div
      :class="classes"
      @click="showModal = true"
    >
      <div class="modal-icon">
        <img
          class="w-full"
          :alt="heading"
          :src="url"
          :srcset=" getSrcset()"
        >
        <div class="modal-icon__overlay u-hover u-inverse u-center-vertical">
          <div class="modal-icon__caption h-full flex items-center justify-center">
            <h2
              v-if="heading"
              class="text-xl text-white font-light px-2"
            >
              {{ heading }}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {defineComponent} from 'vue';
  import { VueFinalModal, ModalsContainer } from 'vue-final-modal'

  export default defineComponent({
    components: {
      VueFinalModal,
      ModalsContainer /* eslint-disable-line vue/no-unused-components */
    },
    props: {
      classes: {
        type: String,
        default: () => '',
      },
      url: {
        type: String,
        default: () => '',
      },
      xOne: {
        type: String,
        default: () => '',
      },
      xTwo: {
        type: String,
        default: () => '',
      },
      heading: {
        type: String,
        default: () => '',
      },
      escToClose: {
        type: Boolean,
        default: true
      },
      height: {
        type: String,
        default: "90vh"
      },
      maxWidth: {
        type: String,
        default: "1200px"
      },
      maxHeight: {
        type: String,
        default: "1000px"
      },
      bgColor: {
        type: String,
        default: "#FFF"
      },
    },
    data: () => ({
      showModal: false
    }),
    computed: {
      // https://www.telerik.com/blogs/passing-variables-to-css-on-a-vue-component
      cssVars() {
        return {
          '--max-width': this.maxWidth,
          '--max-height': this.maxHeight,
          '--bg-color': this.bgColor,
          'margin': '0px auto',
        }
      }
    },
    mounted() {
      // console.log('mounted VueTailwindModal');
    },
    methods: {
      getSrcset() {
        return `${this.xOne} 1x, ${this.xTwo} 2x`
      },
      onOpen() {
        // We need to raise a resize event to ensure the VueCarouselModal can detect
        // the correct size of the slides, and allow us to navigate through the slides
        window.dispatchEvent(new Event("resize"));
      }
    },
  });
</script>

<style scoped>
::v-deep .modal-receptacle {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .modal-content {
  position: relative;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: var(--bg-color);

  /* width: 90vw; */
  max-width: var(--max-width);
  max-height: var(--max-height);
}

::v-deep.modal__content h2 { /* stylelint-disable-line selector-class-pattern */
  padding: 10px 0 5px;
}

::v-deep.modal__content h3 { /* stylelint-disable-line selector-class-pattern */
  font-size: 26px;
  padding-bottom: 10px;
}

::v-deep.modal__content ul { /* stylelint-disable-line selector-class-pattern */
  padding-left: 20px;
  padding-right: 20px;
}

::v-deep.modal__content li { /* stylelint-disable-line selector-class-pattern */
  padding-bottom: 10px;
}

.modal__close { /* stylelint-disable-line selector-class-pattern */
  position: absolute;
  top: 0.5rem;
  right: 3rem;
}

.u-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #6faa27;
  opacity: 0;
}

.u-hover:hover {
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}

.fa-circle {
  background: #6faa27;
  border-radius: 50%;
  height: 1.2em;
  width: 1.2em;
  line-height: 1.2;
}

.fa-circle:hover {
  background: #5f9222;
}

.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
